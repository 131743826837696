.bs-chat-section {
  margin-bottom: 2rem;
  ul,
  li {
    list-style-type: none;
    padding: 0;
  }
  .section-title {
    font-size: 20px;
    line-height: 28px;
  }
  .chat-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    button {
      background-color: #193f34;
      border: none;
      box-shadow: none;
      border-radius: 8px;
      color: #ffffff;
      padding: 0.5rem;
    }
  }
  .chat-section {
    padding: 1.25rem;
    background-color: #fff;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: auto;
  }
  .chat-list {
    display: flex;
    flex-direction: column;
  }
  .chat-item {
    min-width: 150px;
    max-width: 45%;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 0;
    &.buyer {
      margin-left: auto;
      margin-right: 0;
      .name {
        text-align: right;
      }
    }
  }
  .chat-card {
    .name {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .chat-msg {
      padding: 0.6rem;
      border-radius: 4px;
      background-color: #f3f6ee;
    }
    .time {
      display: block;
      text-align: right;
      font-style: italic;
      margin-top: 5;
    }
  }
}
