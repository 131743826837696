.spacing{
    height: 10px;
}
.Inputfield{
    background: #ccc;
    padding: 10px;
}
.float-right{
    float: right;
}
.no-data {
    width: 100%;
    text-align: center;
}